import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A részletes folyamatot az alábbi ábra írja le:`}<br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "/sp-api/workflow.png",
        "alt": "folyamat"
      }}></img></p>
    <p>{`Direkt link:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sp-api/workflow.png"
        }}>{`.png formátum`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sp-api/workflow.pdf"
        }}>{`.pdf formátum`}</a></li>
    </ul>
    <h2>{`Részletek`}</h2>
    <h3>{`#`}{` 1. Enter invoice data`}</h3>
    <p>{`A felhasználó kitölt minden részletet a számla kiállításához.`}</p>
    <h3>{`#`}{` 2. Issue invoice`}</h3>
    <p>{`Majd kiállítja a számlát.`}<br parentName="p"></br>{`
`}{`A Legtöbb számlázó program ekkor küldi el a NAV számára a számla adatait.`}</p>
    <h3>{`#`}{` 3. Run Péntech pre-qualification criteria`}</h3>
    <p>{`Attól függően, hogy a Péntech ajálatkérő gombot hol helyezi el az integráló, a számla előminősítését kérjük elvégezni. Ez az alábbi feltételekből áll:`}</p>
    <ul>
      <li parentName="ul">{`Legyen aktív/végleges a számla.`}</li>
      <li parentName="ul">{`A számla fizetési határideje legyen:`}
        <ul parentName="li">
          <li parentName="ul">{`legalább 30 nap, és`}</li>
          <li parentName="ul">{`maximum 180 nap.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`A cégnek havi 1 millió értékű számlája legyen az előző hónapban, vagy az aktuális törthónapban az adott debtor felé kiállítva. (aktuális számlát is számolva)`}</li>
    </ul>
    <p>{`Ha a feltételeknek mind megfelel a cég és a számla, kikerülhet a Péntech finanszírozás gomja.`}</p>
    <h3>{`#`}{` 4. Calculate indicative offer`}</h3>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://app.swaggerhub.com/apis-docs/pentech-hu/pentech-sales-partner-api/#/SalesPartner/get_calculate"
      }}><inlineCode parentName="a">{`GET /calgulate`}</inlineCode></a>{` végpont meghívásával előzetes ajánlatot kérhet le az integráló rendszer a Péntech-től.`}</p>
    <p>{`Az ajánlat mellett egy (jelenleg nem dokumentált) paraméter, az `}<inlineCode parentName="p">{`isEligible`}</inlineCode>{` fejezi ki, hogy az ajánlat a Péntech előzetes ellenőrzése szerint faktorálható-e.`}</p>
    <h3>{`#`}{` 5. Display financing CTA to customer`}</h3>
    <p>{`Amennyiben az `}<inlineCode parentName="p">{`isEligible`}</inlineCode>{` igaz értékkel tért vissza, az ajánlatot meg lehet jeleníteni a felhasználónak.`}</p>
    <h3>{`#`}{` 6. Receive offer`}</h3>
    <p>{`Amikor a felhasználó a CTA-ra kattint, megjelenik a Péntech ajánlata.`}</p>
    <h3>{`#`}{` 7. Accept offer`}</h3>
    <p>{`Amennyiben ezt a felhasználó elfogadja, …`}</p>
    <h3>{`#`}{` 8. Data processing concent`}</h3>
    <p>{`És elfogadja hogy az adatai a Péntech rendszerében rögzítésre kerülnek (jogi nyilatkozat) …`}</p>
    <h3>{`#`}{` 9. Aggregated data transfer to Péntech`}</h3>
    <p>{`Az integráló rendszer elküldi a szükséges adatokat a `}<a parentName="p" {...{
        "href": "https://app.swaggerhub.com/apis-docs/pentech-hu/pentech-sales-partner-api/#/SalesPartner/post_sales_lead"
      }}><inlineCode parentName="a">{`POST /sales-lead`}</inlineCode></a>{` végpont meghívásával.`}</p>
    <h3>{`#`}{` 9.1.`}</h3>
    <p>{`Az innen visszakapott salesLeadId-re lesz szükség a felhasználónak generálandó link elkészítéséhez.`}<br parentName="p"></br>{`
`}{`Erre a linkre kell átirányítani majd a felhasználót, ahol regisztrálhat, vagy ha már korábban regisztrált, beléphet a Péntech rendszerébe.`}</p>
    <h3>{`További lépések`}</h3>
    <p>{`Az ábrán részletezett további lépések a Péntech rendszerének megismerését szolgálják. Az integráló félnek további dolga alap esetben nincs a folyamatban.`}</p>
    <h2>{`Sztornó és módosító számla`}</h2>
    <p>{`Sztornózás esetében a `}<a parentName="p" {...{
        "href": "https://app.swaggerhub.com/apis-docs/pentech-hu/pentech-sales-partner-api/#/SalesPartner/delete_sales_lead__salesLeadId_"
      }}><inlineCode parentName="a">{`DELETE /sales-lead/cancel`}</inlineCode></a>{` végpontot kell meghívni. Ebben az esetben a Péntech rendszerében elkönyveljük, hogy az adott számlához tartozó esetleges igénylés is sztornózásra kerüljön.`}</p>
    <p>{`Módosítás esetében a folyamat lépései még nem tisztázottak, így jelenleg a sztornózás folyamatát kérjük használni.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      